import { Injectable } from '@angular/core';
import { environment } from '@kolytics/envs';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public config: any = {
    entryRoute: './users',
    navigation: [
      { route: '/reits', token: 'Reits', icon: 'reit' },
      {
        route: '/updates',
        token: 'Updates',
        icon: 'updates',
        children: [
          {
            route: '/updates/reit-updates',
            token: 'Reit Updates',
            icon: 'research',
          },
          {
            route: '/updates/roundup',
            token: 'Weekly Roundup Emails',
            icon: 'video',
          },
        ],
      },
      { route: '/users', token: 'Users', icon: 'users' },
      {
        route: '/research',
        token: 'Research',
        icon: 'research',
        children: [
          // {
          //   route: '/research/podcasts',
          //   token: 'Podcasts',
          //   icon: 'mic'
          // },
          // {
          //   route: '/research/videos',
          //   token: 'Videos',
          //   icon: 'video'
          // },
          {
            route: '/research/reports',
            token: 'Reports',
            icon: 'research',
          },
        ],
      },
      // {
      //   route: '/report',
      //   token: 'Report',
      //   icon: 'report',
      //   children: [
      //     {
      //       route: '/report/user',
      //       icon: 'reit',
      //       token: 'User activity report'
      //     },
      //     {
      //       route: '/report/reit',
      //       icon: 'research',
      //       token: 'REIT report'
      //     }
      //   ]
      // },
      { route: '/settings', token: 'Settings', icon: 'settings' },
      {
        click: () => {
          const accessToken = localStorage.getItem('access_token');
          window.open(`${environment.queryUrl}?token=${accessToken}`, '_blank');
        },
        route: '#',
        token: 'Snapshot Playground',
        icon: 'settings',
      },
    ],
    profile: [{ route: '/account', token: 'My account', icon: 'profile' }],
  };
  constructor() {}

  set(variable: string, value: any): void {
    this.config[variable] = value;
  }

  get(variable: string): any {
    return this.config[variable];
  }
}
