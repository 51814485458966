<div class="navigation--wrapper">
  <div class="navigation--container">
    <div class="navigation-container-left">
      <div class="navigation-logo"></div>
    </div>
    <div class="navigation-container-right">
      @for (n of nav; track n) {
        <div
          class="navigation-link"
          [routerLink]="!n.children ? n.route : undefined"
          [ngClass]="{ active: n.route === currentRoute }"
          (click)="n.click ? n.click() : null"
        >
          <div class="navigation-link-label">{{ n.token }}</div>
          <div class="navigation-link-underline"></div>
          @if (n.children) {
            <klt-hover-popup
              class="dropdown-menu"
              [label]="n.token"
              [children]="n.children"
            >
            </klt-hover-popup>
          }
        </div>
      }
    </div>
    <div class="user-dropdown-box">
      @if (hasQueueItems) {
        <div class="queue-container">
          <klt-loading-spinner
            [size]="'xsmall'"
            [show]="true"
            [label]="'Processing updates...'"
            (click)="toggleQueue()"
          >
          </klt-loading-spinner>
          @if (queueIsOpen) {
            <div class="queue-dropdown" @navigationProfileAnimation>
              <h5>Today's updates</h5>
              @for (item of queueItems; track $index) {
                <div class="queue-item">
                  @if (item.state === 1 || item.state === 3) {
                    <div class="queue-content">
                      <div class="queue-content-row">
                        <span class="queue-content-text">{{ item.name }}</span>
                        @if (item.state === 1) {
                          <span class="queue-content-text-subtitle"
                            >Started.</span
                          >
                        }
                        @if (item.state === 3) {
                          <span class="queue-content-text-subtitle"
                            >Cancelling...</span
                          >
                        }
                      </div>
                      <div class="queue-content-row">
                        @if (item.state === 1 || item.state === 3) {
                          <klt-loading-spinner
                            class="started"
                            [size]="'xsmall'"
                            [show]="true"
                          ></klt-loading-spinner>
                        }
                        <klt-icon
                          class="cancel"
                          icon="delete"
                          size="small"
                          color="error"
                          (click)="cancelQueueItem(item)"
                        >
                        </klt-icon>
                      </div>
                    </div>
                  }
                </div>
              }
              @for (item of queueItems; track $index) {
                <div class="queue-item">
                  @if (item.state === 0) {
                    <div class="queue-content">
                      <div class="queue-content-row">
                        <span class="queue-content-text">{{ item.name }}</span>
                        <span class="queue-content-text-subtitle">Queued.</span>
                      </div>
                      <div class="queue-content-row">
                        @if (item.state === 0) {
                          <klt-loading-spinner
                            class="started"
                            [size]="'xsmall'"
                            [show]="true"
                          >
                          </klt-loading-spinner>
                        }
                        <klt-icon
                          class="cancel"
                          icon="delete"
                          size="small"
                          color="error"
                          (click)="cancelQueueItem(item)"
                        >
                        </klt-icon>
                      </div>
                    </div>
                  }
                </div>
              }
              @for (item of queueItems; track $index) {
                <div class="queue-item">
                  @if (item.state !== 1 && item.state !== 0) {
                    <div class="queue-content">
                      <div class="queue-content-row">
                        <span class="queue-content-text">{{ item.name }}</span>
                        @if (item.state === 2) {
                          <span class="queue-content-text-subtitle"
                            >Finished.</span
                          >
                        }
                        @if (item.state === 4) {
                          <span class="queue-content-text-subtitle"
                            >Cancelled.</span
                          >
                        }
                        @if (item.state === 5) {
                          <span class="queue-content-text-subtitle"
                            >Failed. See email for details.</span
                          >
                        }
                        @if (item.state === 6) {
                          <span class="queue-content-text-subtitle"
                            >Pausing...</span
                          >
                        }
                        @if (item.state === 7) {
                          <span class="queue-content-text-subtitle"
                            >Paused.</span
                          >
                        }
                      </div>
                      <div class="queue-content-row">
                        @if (item.state === 2) {
                          <klt-icon
                            class="tick"
                            icon="tick"
                            size="small"
                            color="cta"
                          ></klt-icon>
                        }
                        <klt-icon
                          class="cancel"
                          icon="delete"
                          size="small"
                          color="error"
                          (click)="cancelQueueItem(item)"
                        >
                        </klt-icon>
                        @if (item.state === 5 || item.state === 4) {
                          <klt-icon
                            class="error"
                            icon="error"
                            size="small"
                            color="error"
                          ></klt-icon>
                        }
                      </div>
                    </div>
                  }
                  <div class="line"></div>
                </div>
              }
            </div>
          }
        </div>
      }
      <div
        class="navigation-link profile-icon"
        (click)="toggleProfile()"
        [ngClass]="{ active: currentRoute == '/account' }"
      >
        <div class="navigation-link-spacer"></div>
        <klt-icon
          [size]="'medium'"
          [icon]="'profile'"
          [color]="currentRoute == '/account' ? 'cta' : 'light'"
        >
        </klt-icon>
      </div>

      @if (isProfileOpen) {
        <div class="navigation-profile" @navigationProfileAnimation>
          <div class="navigation-profile-user">
            <h3>{{ user?.firstName }}</h3>
            <div class="email">{{ user?.email }}</div>
          </div>
          @for (item of profileNav; track item) {
            <div class="navigation-profile-item">
              <div
                class="navigation-profile-content"
                (click)="navigate(item.route)"
                [ngClass]="{ active: item.route === currentRoute }"
              >
                <klt-icon
                  [size]="'small'"
                  [icon]="item.icon"
                  [color]="item.route === currentRoute ? 'cta' : 'dark'"
                >
                </klt-icon>
                <span class="navigation-profile-content-text">{{
                  item.token
                }}</span>
              </div>
            </div>
          }
          <div class="line"></div>
          <div class="navigation-profile-item" (click)="logout()">
            <div class="navigation-profile-content logout">
              <klt-icon
                [size]="'small'"
                [icon]="'log-out'"
                [color]="'dark'"
              ></klt-icon>
              <span class="navigation-profile-content-text">Logout</span>
            </div>
          </div>
        </div>
      }

      @if (navigationIsOpen) {
        <div
          @navigationOverlayAnimation
          class="navigation-overlay"
          (click)="toggleNavigation()"
        ></div>
      }
    </div>
  </div>
  <div class="navigation--line"></div>
</div>
