export enum QueueTaskState {
  Queued = 0,
  Started = 1,
  Finished = 2,
  Canceling = 3,
  Canceled = 4,
  Failed = 5,
  Pausing = 6,
  Paused = 7,
}
