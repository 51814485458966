<div class="app-container" id="app">
  @if (currentParent !== 'login') {
    <klt-navigation
      [user]="accountService.user"
    ></klt-navigation>
  }
  <div class="main-content">
    <main [@routerTransition]="getState(o)">
      <router-outlet #o="outlet" (activate)="onActivate()"></router-outlet>

      @if (currentParent !== 'login') {
        <div class="footer">
          <p>
            Copyright © {{ date | date: 'yyyy' }} Kolytics Ltd.. All rights
            reserved.
            <a
              href="https://www.kolytics.com/terms"
              target="_blank"
              class="terms"
              >Terms</a
              >
            </p>
          </div>
        }
      </main>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="default"
    color="#fff"
    type="ball-clip-rotate"
    [fullScreen]="true"
  ></ngx-spinner>
