import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisplayComponentsComponent } from '@kolytics/shared-components';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: 'components', component: DisplayComponentsComponent },
  {
    path: '',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'updates',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/updates/updates.module').then((m) => m.UpdatesModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'reits',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/reits/reits.module').then((m) => m.ReitsModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'research',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/research/research.module').then((m) => m.ResearchModule),
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/report/report.module').then((m) => m.ReportModule),
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
