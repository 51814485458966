import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { AccountService } from './pages/account/services';
import { MetatagsService } from './pages/reits/services/metatags.service';
import { routerTransition } from './shared/animations/router.animations';
import { DatePipe } from '@angular/common';
import { NgxSpinnerComponent } from 'ngx-spinner';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PrimeNGConfig } from 'primeng/api';
import { customTheme } from '@kolytics/primeng';

@Component({
  selector: 'klt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
  standalone: true,
  imports: [NavigationComponent, RouterOutlet, NgxSpinnerComponent, DatePipe],
})
export class AppComponent implements OnInit {
  public config: PrimeNGConfig = inject(PrimeNGConfig);

  currentRoute: string | undefined;
  currentParent: string | undefined = 'login';

  date = new Date();

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private metatagsService: MetatagsService,
    public accountService: AccountService,
  ) {
    this.config.theme.set(customTheme);
    router.events.subscribe(() => {
      this.currentRoute = this.router.url.split('?')[0];
      this.currentParent = this.currentRoute.split('/')[1];
    });
  }

  async ngOnInit() {
    if (this.currentParent && this.currentParent !== 'login') {
      this.getAccount();
    }
    this.setMetaTitle();
  }

  getAccount() {
    this.accountService.getMyAdminAccount().pipe(take(1)).subscribe();
  }

  setMetaTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.metatagsService.updateTitle(route.data.title);
      });
  }

  getState(outlet: RouterOutlet): string {
    return outlet.activatedRouteData.state;
  }

  onActivate(): void {
    window.scroll(0, 0);
  }
}
