<div class="hover-popup--wrapper">
  <div class="popup-content">
    @for (child of children; track child) {
      <div
        class="menu-item"
        [routerLink]="child.route"
        >
        <div>
          <klt-icon [icon]="child.icon" size="small"></klt-icon>
        </div>
        <span>{{ child.token }}</span>
      </div>
    }
  </div>
</div>
