import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { apiConfigFactory } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { AppComponent } from './app/app.component';
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from '@kolytics/shared-api';
import { tokenGetter } from '../../client/src/app/app.module';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from './app/shared/shared.module';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  BrowserModule,
  HammerModule,
  bootstrapApplication,
} from '@angular/platform-browser';
import { UnauthorizeInterceptorService } from '../../../libs/shared-components/src/lib/interceptors/unauthorize-interceptor.service';
import { TokenInterceptorService } from './app/shared/interceptors/token-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

const params: ConfigurationParameters = {
  basePath: environment.baseApiUrl,
  credentials: {
    Bearer: () => `Bearer ${localStorage.getItem('access_token')}`,
  },
};

Sentry.init(environment.sentry);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      SharedModule,
      HammerModule,
      NgxSpinnerModule,
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
        },
      }),
      ApiModule.forRoot(apiConfigFactory),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizeInterceptorService,
      multi: true,
    },
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
