import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from 'apps/admin/src/app/shared/services';
import { environment } from 'apps/admin/src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { PasswordFailedTypesEnum } from '../../../shared/enums';
import {
  IMyAccountResponse,
  IUpdateAccountDetailRequest,
  IUpdatePasswordRequest,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  user: any;
  updateAccountDetail(data: IUpdateAccountDetailRequest): Observable<any> {
    const url = `${environment.apiUrl}/admin/saveAccountDetails`;
    // NOTE: specify the return type
    return this.http.post<any>(url, data).pipe(tap((x) => {}));
  }
  updatePassword(data: IUpdatePasswordRequest): Observable<any> {
    const url = `${environment.apiUrl}/admin/saveUserPassword`;
    // NOTE: specify the return type
    return this.http.post<any>(url, data).pipe(
      catchError((err) => {
        if (
          err?.error?.message === PasswordFailedTypesEnum.InvalidCurrentPassword
        ) {
          return throwError(PasswordFailedTypesEnum.InvalidCurrentPassword);
        }

        return throwError(PasswordFailedTypesEnum.UnknownFailure);
      }),
    );
  }

  constructor(
    protected readonly http: HttpClient,
    protected readonly sessionService: SessionService,
  ) {}
  public getMyAdminAccount(): Observable<IMyAccountResponse> {
    const url = `${environment.apiUrl}/admin/myAccount`;
    return this.http
      .get<IMyAccountResponse>(url)
      .pipe(tap((data: any) => (this.user = data)));
  }

  public getMyAccount(): Observable<IMyAccountResponse> {
    const url = `${environment.apiUrl}/myAccount`;
    return this.http.get<IMyAccountResponse>(url);
  }
}
