import { Component, Input, OnInit } from '@angular/core';
import {
  navigationOverlayAnimation,
  navigationProfileAnimation,
} from '../../shared/animations/navigation.animation';
import { IconComponent } from '../../../../../../libs/shared-assets/src/lib/components/icon/icon.component';
import { RouterLink } from '@angular/router';


interface IChildRoute {
  token: string;
  icon: string;
  route: string;
}

@Component({
    selector: 'klt-hover-popup',
    templateUrl: './hover-popup.component.html',
    styleUrls: ['./hover-popup.component.scss'],
    animations: [navigationProfileAnimation(), navigationOverlayAnimation()],
    standalone: true,
    imports: [
    RouterLink,
    IconComponent
],
})
export class HoverPopupComponent implements OnInit {
  @Input() label = '';
  @Input() children: IChildRoute[] = [];

  constructor() {}

  ngOnInit() {}
}
