import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

export interface IValidateUpload {
  sheetIsValid: boolean;
  validateReport: { sheetName: string; errors: string[] }[];
}

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  upload(file: File, url: string, isDraft = true): Observable<number> {
    // const blob = new Blob([file], {type: file.type});

    // create a new multipart-form for file
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let headers = new HttpHeaders({
      'X-Draft': String(isDraft),
    });
    return this.http.post<number>(url, formData, {
      headers,
      reportProgress: true,
    });
  }

  validate(
    file: File,
    url: string,
    isDraft = true,
  ): Observable<IValidateUpload> {
    // const blob = new Blob([file], {type: file.type});

    // create a new multipart-form for file
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let headers = new HttpHeaders({
      'X-Draft': String(isDraft),
    });

    return this.http.post<IValidateUpload>(url, formData, {
      headers,
      reportProgress: true,
    });
  }
}
