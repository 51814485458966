import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { QueueTaskState } from '@kolytics/types/enum';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import { lastValueFrom, take } from 'rxjs';
import {
  navigationOverlayAnimation,
  navigationProfileAnimation,
} from '../../shared/animations/navigation.animation';
import { SessionService } from '../../shared/services';
import { GlobalService } from '../../shared/services';
import { IconComponent } from '../../../../../../libs/shared-assets/src/lib/components/icon/icon.component';
import { LoadingSpinnerComponent } from '../../../../../../libs/shared-components/src/lib/components/loading-spinner/loading-spinner.component';
import { HoverPopupComponent } from '../hover-popup/hover-popup.component';
import { NgClass } from '@angular/common';
import { QueueService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [navigationProfileAnimation(), navigationOverlayAnimation()],
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    HoverPopupComponent,
    LoadingSpinnerComponent,
    IconComponent,
  ],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input() user: any;
  currentRoute = '';
  nav: Array<any> = [];
  profileNav: any; // ProfileNavigation;
  isProfileOpen = false;

  queueItems: any[] = [];
  queueInterval: any;
  queueIsOpen = false;
  get hasQueueItems() {
    return this.queueItems && this.queueItems.length > 0;
  }

  constructor(
    private router: Router,
    private global: GlobalService,
    private session: SessionService,
    private queueService: QueueService,
    private toastService: KltToastService,
  ) {
    this.nav = this.global.get('navigation');
    this.profileNav = this.global.get('profile');
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentRoute = `/${val.urlAfterRedirects.split('/')[1]}`;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getQueue(true);
    this.queueInterval = setInterval(async () => {
      await this.getQueue();
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.queueInterval) {
      clearInterval(this.queueInterval);
    }
  }
  get navigationIsOpen() {
    return this.queueIsOpen || this.isProfileOpen;
  }
  toggleNavigation() {
    this.queueIsOpen = false;
    this.isProfileOpen = false;
  }

  toggleQueue(): void {
    this.queueIsOpen = !this.queueIsOpen;
  }

  toggleProfile(): void {
    this.isProfileOpen = !this.isProfileOpen;
    this.queueIsOpen = false;
  }

  closeProfile(): void {
    this.isProfileOpen = false;
  }
  navigate(route: string): void {
    if (route.indexOf('mailto:') !== -1) {
      window.open(route, '_blank');
    } else {
      this.router.navigate([route]).then();
    }
    this.closeProfile();
  }
  async logout(): Promise<any> {
    this.session.logout();
    this.router.navigate(['/login']);
  }
  async search(query: any | string): Promise<void> {
    // this.searchValueQuery = typeof(query) === 'string' ? query : query?.formValue;
    // if (this.searchValueQuery) {
    //   this.data.values = this.valuesForSearch?.filter(value => value.name?.toUpperCase().includes(this.searchValueQuery.toUpperCase()));
    // } else {
    //   this.data.values = this.valuesForSearch;
    // }
  }

  cancelQueueItem(item: any) {
    this.queueService
      .apiQueueCancelTaskIdGet(item.id)
      .pipe(take(1))
      .subscribe();
  }

  async getQueue(initialFetch: boolean = false) {
    const result = await lastValueFrom(this.queueService.apiQueueStatusGet());

    if (!initialFetch) {
      const completed = this.queueItems
        .filter((e) => e.state === QueueTaskState.Finished)
        .map((e) => e.name);
      const newCompleted = result.data
        .filter(
          (e: any) =>
            !completed.includes(e.name) && e.state === QueueTaskState.Finished,
        )
        .map((e: any) => e.name);
      if (newCompleted.length > 0) {
        const reitNames = newCompleted.join(' and ');
        this.toastService.success(`${reitNames} successfully uploaded.`, 5000);
      }
    }
    this.queueItems = result.data;
  }
}
