import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MemberUpsertComponent } from './components/member-upsert/member-upsert.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ReitUpsertDialogComponent } from './components/reit-upsert-dialog/reit-upsert-dialog.component';
import { TokenInterceptorService } from './shared/interceptors/token-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { ReitUpdateAddComponent } from './modals/reit-update-add/reit-update-add.component';
import { FilterCompanyComponent } from './modals/filter-company/filter-company.component';
import { HoverPopupComponent } from './components/hover-popup/hover-popup.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderInterceptorService } from './shared/interceptors/loader-interceptor.service';
import { SectorDataUploadComponent } from './modals/sector-data-upload/sector-data-upload.component';
import { UnauthorizeInterceptorService } from '../../../../libs/shared-components/src/lib/interceptors/unauthorize-interceptor.service';
import { EmailDistributionModalComponent } from './modals/email-distribution-modal/email-distribution-modal.component';
import { ReitDraftPublishComponent } from './modals/reit-draft-publish/reit-draft-publish.component';
import { JwtModule } from '@auth0/angular-jwt';
import { tokenGetter } from '../../../client/src/app/app.module';
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from '@kolytics/shared-api';
import { environment } from '../environments/environment';
import { DataAnalyticsUploadComponent } from './modals/data-analytics-upload/data-analytics-upload.component';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.baseApiUrl,
    credentials: {
      Bearer: () => `Bearer ${localStorage.getItem('access_token')}`,
    },
  };
  return new Configuration(params);
}

