import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SessionService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService {
  constructor(private sessionService: SessionService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.sessionService.getAccessToken();
    if (token) {
      req = this.addHeaders(req, token);
    }
    return next.handle(req).pipe(
      catchError((err, caught) => {
        // if (err.status === 401) {
        //   return this.handle401Error(req, next, err);
        // }
        throw err;
      }),
    );
  }

  private addHeaders(request: any, token: string) {
    request = this.addAuthHeader(request, token);
    return request;
  }

  addAuthHeader(request: any, token: string): any {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
